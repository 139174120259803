<template>
    <a-row>
        <a-col :md="12" :xl="12">
            <a-card :bordered="false" class="header-solid h-full card-profile-information" :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }" :headStyle="{paddingRight: 0,}">
                <template #title>
                    <h6 class="font-semibold m-0">Pedido</h6>
                </template>
                <a-button type="link" slot="extra">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="fill-muted" d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z" fill="#111827"/>
                        <path class="fill-muted" d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z" fill="#111827"/>
                    </svg>
                </a-button>
                <a-row>
                    <a-col :md="9">
                            <a-card class="card-project">
                            <img
                                slot="cover"
                                alt="example"
                                :src="data.imageOfdescription"
                            />
                        </a-card>
                    </a-col>
                </a-row>     
                <hr class="my-25">
                <a-descriptions :title="`Orden [${data.id}]`" :column="1">
                    <a-descriptions-item label="Producto">
                           {{data.productName}}
                    </a-descriptions-item>
                        <a-descriptions-item label="Cantidad">
                        {{data.quantity}}
                    </a-descriptions-item>
                    <a-descriptions-item label="Precio">
                       RD$ {{data.pricing}}.00
                    </a-descriptions-item>
                    <a-descriptions-item label="Estatus de la orden">
                        <a-tag class="tag-status" :class="true ? 'ant-tag-primary' : 'ant-tag-muted'">
                            {{ data.status }}
                        </a-tag>
                    </a-descriptions-item>
                    <a-descriptions-item label="Direccion de entrega">
                       {{data.address}}
                    </a-descriptions-item>
                    <a-descriptions-item label="Nota">
                        {{data.description}}
                    </a-descriptions-item>
                </a-descriptions>
	        </a-card>
        </a-col>
    </a-row>
	<!-- Profile Information Card -->
	
	<!-- / Profile Information Card -->

</template>

<script>

	export default ({
        props: {
			data: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
			}
		},
	})

</script>
<style lang="scss" scoped>
 .image-container{
        height: 250px;
      
        object-fit: cover;
    }
</style>