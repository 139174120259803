	
<template>
	<div>
    <a-breadcrumb class="mb-10">
			<a-breadcrumb-item><router-link to="/orders"> Inicio / Ordenes</router-link></a-breadcrumb-item>
			<a-breadcrumb-item>{{ this.$route.name }}</a-breadcrumb-item>
		</a-breadcrumb>
        <CardOrder
		:data="order"
		></CardOrder>
	</div>
</template>

<script>
import CardOrder from '@/components/Cards/CardOrder'
import { getSingleOrderById } from '@/services/orders/orders';
import  config   from '@/utils/table.config'
	// "Authors" table component.

	export default ({
		components: {
            CardOrder
		},
		data() {
			return {
				order:null,
			}
		},
		created(){
			this.getOrder()
		},
		methods:{
			async getOrder(){
				await getSingleOrderById((data)=>{
					
					this.order=data
				},this.$route.params.id)
			}
		}
	})
/*
address
: 
"Un lugar de la mancha"
color
: 
"Blanco"
dateCreated
: 
1689391632733
description
: 
"test"
id
: 
"CO2ASxlhA2XRlzxIKs17"
imageOfdescription
: 
"https://resincraftsblog.com//wp-content/uploads/2019/06/Ink-Keychain.jpg"
numberPhone
: 
"8096079600"
pricing
: 
800
productName
: 
"Llavero"
quantity
: 
"2"
status
: 
"ENVIADA"
storeInfo
: 
{name: 'Magicamente creativo', description: 'Tienda de diseños lindos', profilePicture: 'https://media.istockphoto.com/id/902957562/photo/i…20&c=cRPqSnxSKsBc5jHTeAvY3s5-6t21mU2aJNlFN2Bfe1I=', address: 'Santo domingo oeste', numberPhone: '8095350093', …}
userId
: 
"RBonujAjDbdf0ASoxrIajq2JYS93"
userName
: 
"Asiel Luciano"
*/
</script>

<style lang="scss">
</style>

